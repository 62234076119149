@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 16px 32px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $btnprimaryover;
    border: none;
    margin-top: 1rem;
    border-radius: 10px;
    width: 100%;
    max-width: 200px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $btnprimary;
    }
  } 

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    background: $primary;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            max-width: 65%;
        }
    }
    &.aboutus-header-section{
        background: $white;
        background: url("../../assets/images/map.png") no-repeat center;
        background-size: contain;
        padding: 0rem 0px 1rem;
        min-height: 495px;
        .header-navigation{
            background: $primary;
            padding: 15px 165px;
        }
        .custom-container{
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding: 0rem 3rem 3rem;
            h1{
                color: $black;
                margin-bottom: 1rem;
                font-size: 55px;
            }
            p{
                margin: 0px;
                color: $black;
                font-size: 30px;
            }
            .contact-info-block{
                margin-top: 5rem;
                h3{
                    font-size: 24px;
                    margin: 0px 0px 0.5rem;
                }
                p{
                    margin: 0px 0px 0.8rem;
                    font-size: 16px;
                    color: #7E8492;
                }
                a{
                    color: $primary;
                    padding-bottom: 5px;
                    border-bottom: 1px solid $primary;
                    &:hover{
                        color: $teritary;
                        border-bottom: 1px solid $teritary;
                    }
                }
            }
        }
        &.support-section{
            min-height: auto;
            padding: 2rem 0px;
            background-position: center -16px;
            .custom-container{
                .contact-info-block{
                    margin-top: 0px;
                }
            }
        }
    }
}
.is-sticky{
    .header-navigation{
        padding: 0px !important;
    }
}
.about-blocks-section{
    position: relative;
    .custom-container{
        padding: 2.5rem 2rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        h2{
            font-size: 32px;
            font-weight: 700;
            color: $primary;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-weight: 400;
            font-family: $font-secondary;
            line-height: 36px;
        }
        .about-img{
            img{
                width: 100%;
                max-width: 510px;
            }
        }
        .right{
            padding-right: 6rem;
        }
        .custom-margin{
            margin-bottom: 5rem;
        }
    }
}
.about-partners-section{
    padding: 0rem 165px 5rem;
    background: $white;
    .partners-text-box{
        background: $white;
        border-radius: 30px;
        color: $primary;
        min-height: 645px;
        position: relative;
        z-index: 1;
        left: 8rem;
        border: 1px solid $input-border;
        padding: 2rem;
        h2{
            margin: 0px 0px 20px;
            font-size: 36px;
            font-weight: 600;
        }
        p{
            font-size: 18px;
            margin: 0px 0px 60px;
            font-weight: 400;
            color: #7E8492;
            a{
                color: $secondary
            }
        }
        .about_form{
            .p-component{
                border-radius: 8px;
                border: 1px solid $input-border;
                height: 45px;
                font-size: 16px;
                font-weight: 400;
                margin-top: 5px;
                &.p-inputtextarea{
                    height: 130px;
                }
                &::placeholder {
                    color: #7E8492;
                    font-weight: 400;
                    font-size: 15px;
                  }
                  
                &::-ms-input-placeholder { 
                    color: #7E8492;
                    font-weight: 400;
                    font-size: 15px;
                  }
            }
            .goto-plans-button{
                @include button;
                height: auto;
            }
            .custom-phoneno-field{
                width: 100%;
                .react-tel-input{
                    margin-top: 5px;
                    .form-control {
                        width: 100%;
                        height: 45px;
                        border-radius: 8px;
                        border: 1px solid $input-border;
                    }
                    .flag-dropdown{
                        border-radius: 8px 0 0 8px;
                        border: none;
                        background-color: $input-border;
                        .selected-flag{
                            border-radius: 8px 0 0 8px;
                            background-color: $input-border;
                        }
                        &:hover{
                            background-color: $input-border;
                        }
                    }
                }
                .error-message{
                    top: 2px;
                }
            }
        }
    }
    .benefits-img{
        img{
            width: 100%;
        }
    }
}

// Responsive CSS
@media (min-width:1200px) and (max-width:1460px){
    .inner-header-section{
        &.aboutus-header-section{
            .header-navigation{
                padding: 10px 40px;
            }
        }
    }
    .is-sticky{
        .header-navigation{
            padding: 0px !important;
        }
    }
    .about-blocks-section{
        &::after{
            display: none;
        }
        &::before{
            display: none;
        }
    }
    .about-partners-section{
        padding: 0rem 40px 5rem;
        .partners-text-box{
            left: 2rem;
        }
        .option-box{
            right: 2rem;
            position: relative;
        }
    }
}
@media (max-width:960px){
    .inner-header-section{
        &.aboutus-header-section{
            min-height: auto;
            .header-navigation{
                padding: 5px 20px;
            }
            .custom-container{
                padding: 2rem 3rem 0rem;
                .contact-info-block{
                    margin-top: 2rem;
                    a{
                        margin-bottom: 10px;
                        display: inline-block;
                    }
                    h3{
                        font-size: 18px;
                    }
                }
                h1{
                    font-size:28px;
                }
                p{
                    font-size:22px;
                }
            }
        }
    }
    .is-sticky{
        .header-navigation{
            padding: 0px !important;
        }
    }
    .about-blocks-section{
        &::before,&::after{
            display: none;
        }
        .custom-container{
            padding: 0rem 2rem 0rem;
            h2{
                font-size: 22px;
            }
            .right{
                padding-right: 0rem;
            }
            p{
                font-size:18px;
                line-height: 32px;
            }
            .custom-margin{
                margin-bottom: 1rem;
                &.column-reverse{
                    flex-direction: column-reverse;
                }
            }
        }
    }
    .about-partners-section{
        padding: 0rem 20px 3rem;
        .partners-text-box{
            left: 0px;
            h2{
                font-size: 26px;
            }
            p{
                font-size: 16px;
                margin: 0px 0px 25px;
            }
        }
    }
}
@media (min-width:768px) and (max-width:960px){
    .about-blocks-section{
        .custom-container{
            .custom-margin{
                &.column-reverse{
                    flex-direction: row;
                }
            }
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .about-partners-section{
        padding: 0rem 20px 3rem;
        .partners-text-box{
            left: 0px;
            h2{
                font-size: 26px;
            }
            p{
                font-size: 16px;
                margin: 0px 0px 25px;
            }
        }
    }
    .about-blocks-section{
        &::before,&::after{
            display: none;
        }
        .custom-container{
            padding: 3rem 2rem 0rem;
            h2{
                font-size: 22px;
            }
            .right{
                padding-right: 0rem;
            }
            p{
                font-size:18px;
                line-height: 32px;
            }
            .custom-margin{
                margin-bottom: 1rem;
            }
        }
    }
    .inner-header-section{
        &.aboutus-header-section{
            min-height: auto;
            .header-navigation{
                padding: 10px 20px;
            }
        }
        .header-text{
            margin-top: 0rem;
        }
    }
    .about-blocks-section{
        &::after{
            display: none;
        }
        &::before{
            display: none;
        }
    }
}
@media (min-width:1461px) and (max-width:1750px){
    .inner-header-section{
        &.aboutus-header-section{
            .header-navigation{
                padding: 15px 40px;
            }
        }
    }
    .is-sticky{
        .header-navigation{
            padding: 0px !important;
        }
    }
}