@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 16px 32px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 200px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $primary;
    }
  } 

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    background: $primary;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 36px;
            margin: 25px auto;
            width: 100%;
            max-width: 65%;
        }
    }
    &.aboutus-header-section{
        background: $white;
        background: url("../../assets/images/map.png") no-repeat center;
        background-size: contain;
        padding: 0rem 0px 1rem;
        min-height: 495px;
        .header-navigation{
            background: $primary;
            padding: 15px 165px;
        }
        .custom-container{
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding: 0rem 3rem 3rem;
            h1{
                color: $black;
                margin-bottom: 1rem;
                font-size: 55px;
            }
            p{
                margin: 0px;
                color: $black;
                font-size: 32px;
            }
            .contact-info-block{
                margin-top: 5rem;
                h3{
                    font-size: 24px;
                    margin: 0px 0px 0.5rem;
                }
                p{
                    margin: 0px 0px 0.8rem;
                    font-size: 16px;
                    color: #7E8492;
                }
                a{
                    color: $primary;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $input-border;
                    &:hover{
                        color: $secondary;
                        border-bottom: 1px solid $secondary;
                    }
                }
            }
        }
    }
}
.about-blocks-section{
    position: relative;
    .custom-container{
        padding: 5rem 2rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        h2{
            font-size: 32px;
            font-weight: 700;
            color: $primary;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-weight: 400;
            font-family: $font-secondary;
            line-height: 36px;
        }
        .about-img{
            img{
                width: 100%;
                max-width: 510px;
            }
        }
        .right{
            padding-right: 6rem;
        }
        .custom-margin{
            margin-bottom: 5rem;
        }
    }
}
.about-partners-section{
    padding: 0rem 165px 5rem;
    background: $white;
    .partners-text-box{
        background: $white;
        border-radius: 30px;
        color: $primary;
        min-height: 645px;
        position: relative;
        z-index: 1;
        left: 8rem;
        border: 1px solid $input-border;
        padding: 2rem;
        h2{
            margin: 0px 0px 20px;
            font-size: 36px;
            font-weight: 600;
        }
        p{
            font-size: 18px;
            margin: 0px 0px 60px;
            font-weight: 400;
            color: #7E8492;
            a{
                color: $secondary
            }
        }
        .about_form{
            .p-component{
                border-radius: 8px;
                border: 1px solid $input-border;
                height: 45px;
                font-size: 16px;
                font-weight: 400;
                &.p-inputtextarea{
                    height: 130px;
                }
                &::placeholder {
                    color: #7E8492;
                    font-weight: 400;
                    font-size: 15px;
                  }
                  
                &::-ms-input-placeholder { 
                    color: #7E8492;
                    font-weight: 400;
                    font-size: 15px;
                  }
            }
            .goto-plans-button{
                @include button;
                height: auto;
            }
        }
    }
    .benefits-img{
        img{
            width: 100%;
        }
    }
    .error-message{
        color: $error-msg;
        font-size: 13px;
        margin: 5px 2px;
    }
}

// Responsive CSS

.is-sticky .header-navigation{
    padding: 0 !important;
}

@media (min-width: 961px) and (max-width: 1199px){
    .inner-header-section.aboutus-header-section .header-navigation{
        padding: 10px 20px;
    }
}
@media (min-width: 1200px) and (max-width: 1460px){
    .inner-header-section.aboutus-header-section .header-navigation{
        padding: 10px 40px;
    }
}
@media (max-width: 960px){
    .inner-header-section.aboutus-header-section .header-navigation {
        padding: 5px 20px;
        min-height: auto;
    }
    .inner-header-section .header-text p{
        max-width: 100% !important;
    }
}