@import '../../assets/scss/variables';

// **** Terms and Conditions Page CSS **** //
.tc-block{
    .terms-and-conditions-section{
        padding: 2rem 2rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        p{
            font-size: 16px;
            line-height: 30px;
            margin: 10px 0px 10px;
            font-family: $font-secondary;
            a{
                color: $primary;
                text-decoration: underline;
            }
        }
        .terms-section{
            h2{
                font-size: 18px;
                font-weight: 600;
                color: $primary;
                font-family: $font-base;
                margin-bottom: 1rem;
                span{
                    background: $teritary;
                    border-radius: 5px;
                    color: $white;
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 500;
                    height: 30px;
                    line-height: 30px;
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                }
            }
        }
        ul{
            margin: 0px;
            padding: 5px 0px;
            counter-reset: item;
            &.sub-number-ol{
                counter-reset: item;
                list-style-type: none;
                margin: 0px;
                padding: 5px 0px;
                li{
                    counter-increment: item;
                    margin-bottom: 18px;
                    font-family: $font-secondary;
                    padding-left: 5px;
                    span{
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 27px;
                        &.numbers{
                            color: $teritary;
                            font-weight: 500;
                            margin-right: 10px;
                        }
                    }
                    a{
                        color: $primary;
                        text-decoration: underline;
                    }
                    ol{
                        margin-left: 1.7rem;
                        padding: 0px;
                        margin-top: 10px;
                        li{
                            list-style: none;
                        }
                    }
                    &:last-child{
                        margin-bottom: 0px;  
                    }
                }
            }
            ul{
                li{
                    &::before{
                        display: none !important;
                    }
                }
            }
        }
    }
    &::after{
        content: '';
        bottom: 10%;
        background-size: 65%;
    }
    &::before{
        background-size: 65%;
    }
}
@media (max-width:960px){
    .tc-block{
        .terms-and-conditions-section{
            padding: 1rem 20px;
            .table-responsive{
                width: 100%;
                overflow: scroll;
                .content-table{
                    width: 900px;
                }
            }
            ol.sub-number-ol li a{
                word-break: break-all;
            }
        }
    }
}