@import '../../assets/scss/variables';

.affiliate-header-section {
    background: url(../../assets/images/affiliate-header-banner.jpg) no-repeat;
    background-size: cover !important;
    .header-text {
        .header-text-box{
            border-radius: 10px;
            background: rgba(10, 9, 23, 0.75);
            padding: 60px 50px;
            color: #ffffff;
            width: 95%;
            max-width: 975px;
            margin: 12rem auto 0px;
        }
        .go-to-home{
            padding: 20px 50px;
            box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.15);
            background: $btnprimaryover;
            border: none;
            margin-top: 2rem;
            border-radius: 10px;
            color: $white;
            display: inline-block;
            margin:2rem auto 0px;
            font-weight: 400;
            font-size: 18px;
            .pi-chevron-left{
                font-size: 14px;
                position: relative;
                top: 1px;
                margin-right: 3px;
            }
            &:hover{
                background: $btnprimary;
            }
        }
    }
}
.affiliate-program-section {
    .choose-plans-button {
        padding: 24px 40px;
        box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.15);
        background: $btnprimaryover;
        border: none;
        margin-top: 2rem;
        border-radius: 50px;
        color: $white;
        display: inline-block;
        margin: 2rem auto 0px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        &:hover{
            background: $white;
        }
    }
}
.affiliate-options{
    .option-box{
        margin: 15px 20px 20px 20px;
        padding: 60px 20px;
        p{
            font-size: 17px;
        }
        .p-image{
            img{
                height: 70px;
            }
        }
    }
    .p-carousel-container{
        .p-link{
            width: 50px;
            height: 50px;
            border: 1px solid $secondary;
            background: $secondary;
            .p-icon{
                width: 18px;
                height: 18px;
                color: $white;
            }
            &:hover{
                background: $primary;
                border: 1px solid $primary;
                .p-icon{
                    color: $white;
                }
            }
            &.p-disabled{
                background: $quaternary;
                opacity: 0.75;
                .p-icon{
                    color: $secondary;
                }
            }
        }
        .p-carousel-items-content{
            margin-top: 0rem;
        }
    }
}
.affiliate-program-section{
    padding: 2rem 165px 2rem;
    .option-box{
        .affiliate-program-image{
            img{
                width: 100%;
                max-width: 525px;
            }
        }
    }
}
.steps-section{
    padding: 5rem 165px 1rem;
    .card{
        padding: 2rem 0rem 1rem;
        background: url(../../assets/images/map-affiliate.png) no-repeat center;
        background-size: contain;
    }
    h2{
        margin: 0px;
    }
    .relative{
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        .steps-box{
            position: relative;
            width: 100%;
            max-width: 400px;
            height: 400px;
            display: flex;
            justify-content: center;
            background: url(../../assets/images/steps-bg.png) no-repeat;
            background-size: 100%;
            background-position: center;
            z-index: 1;
            margin:0 auto;
            h3{
                font-size: 22px;
                font-weight: 500;
                color: $secondary;
                text-align: center;
                width: 100%;
                max-width: 60%;
                line-height: 32px;
                margin-top: 7rem;
                p{
                    font-size: 75px;
                    font-weight: 600;
                    margin: 0rem 0rem 2rem;
                    &.secondary-color{
                        color: $teritary;
                    }
                }
            }
            .p-image{
                display: block;
            }
            .steps-icon{
                width: 85px;
                height: 85px;
                display: flex;
                border-radius: 50%;
                background: $secondary;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 15px;
                top: 70px;
                &.secondary-color{
                    background: $teritary;
                }
                .p-image{
                    img{
                        width: 100%;
                        max-width: 40px;
                    }
                }
            }
        }
        .stepsnumber{
            width: 30px;
            height: 260px;
            background: $secondary;
            position: absolute;
            color: $white;
            right: 70px;
            top: 68px;
            z-index: 0;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &::after{
                content: '';
                position: absolute;
                top: 0;
                right: -75px;
                width: 0px;
                height: 0px;
                border-left: 75px solid $secondary;
                border-top: 130px solid transparent;
                border-bottom: 130px solid transparent;
            }
            p{
                display: block;
                position: relative;
                left: 32px;
                z-index: 9;
                font-size: 45px;
                text-align: center;
                line-height: 30px;
                span{
                    font-size: 18px;
                    display: block;
                    text-transform: uppercase;
                }
            }
            &.secondary-color{
                background: $teritary;
                &::before{
                    border-right: 75px solid $teritary;
                }
                &::after{
                    border-left: 75px solid $teritary;
                }
            }
        }
    }
}
.sales-info-section{
    padding: 2rem 165px 6rem;
    .info-box{
        border-radius: 30px;
        background: $primary;
        padding: 50px;
        .div-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            padding-bottom: 15px;
            padding-top:45px;
            ul{
                margin: 0px;
                padding: 0px;
                li{
                    list-style: none;
                    color: $white;
                    margin-right: 1rem;
                    h3{
                        margin: 0px;
                        font-size: 30px;
                        font-weight: 500;
                    }
                    p{
                        margin: 0px;
                        font-size: 18px;
                        font-weight: 300;
                    }
                }
            }
            .right{
                text-align: right;
                color: $white;
                p{
                    margin: 0px;
                    font-size:20px;
                    font-weight: 500;
                    span{
                        font-size:18px;
                        font-weight: 300;
                        display: block;
                        margin: 2px 0px;
                    }
                }
            }
            &.first-row{
                padding-top: 0px;
            }
        }
        .info_p{
            font-size: 16px;
            font-style: italic;
            font-weight: 300;
            color: $white;
            margin: 50px 0px 0px;
        }
    }
}
.getting-started-section{
    .getting-started-box{
        text-align: left;
        padding: 45px;
        h2{
            text-align: left;
        }
        p{
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            margin: 0px 0px 40px;
            line-height: 36px;
        }
        ul{
            margin: 0px;
            padding: 0px;
            display: flex;
            li{
                list-style: none;
                margin-right: 25px;
                h3{
                    margin: 0px 0px 10px;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                }
                p{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28.8px;
                }
                span{
                    &.number{
                        width: 65px;
                        height: 65px;
                        background: $teritary;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 35px;
                        color: $white;
                        margin-top: 5px;
                    }
                }
                .view-all-button{
                    display: block;
                    margin: 0px;
                }
            }
        }
    }
    .getting-started-right-box{
        border-radius: 45px;
        background-image: url(../../assets/images/get-started-image.png);
        min-height: 950px;
        position: relative;
        left: -5rem;
        display: flex;
        align-items: center;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: $secondary;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            border-radius: 45px;
        }
        .info{
            position: relative;
            z-index: 1;
            padding: 45px;
            left: 4rem;
            width: 90%;
            h2{
                text-align: left;
                color: $white;
                line-height: 65px;
                margin-bottom: 50px;
            }
            a{
                margin: 20px 0px 0px 0px;
                display: block;
                max-width: 100%;
                &.btn-white{
                    background: $white;
                    color: $primary;
                    &:hover{
                        background: $btnprimaryover;
                        color: $white;
                    }
                }
            }
        }
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1460px){
    .affiliate-program-section, .sales-info-section{
        padding: 1rem 40px 2rem;
    }
    .steps-section{
        padding: 2rem 40px 0.5rem;
        .stepsnumber{
            right: 70px;
        }
    }
    .affiliate-options{
        top: -6rem;
    }
    .getting-started-section{
        padding: 2rem 40px;
        .getting-started-right-box{
            .info{
                padding: 40px;
                left: 4rem;
                h2{
                    font-size: 40px;
                    line-height: 55px;
                    width: 90%;
                    margin-bottom: 35px;
                }
                a{
                    display: block;
                }
            }
        }
    }
}

@media (max-width:768px){
    .affiliate-header-section{
        background-position: center;
        .header-text{
            .go-to-home{
                padding: 10px 40px;
                font-size: 16px;
            }
        }
    }
    .affiliate-options{
        top: 0rem;
        .option-box {
            margin: 15px 0px 20px 0px;
            padding: 25px 10px;
        }
    }
    .affiliate-program-section,.sales-info-section {
        padding: 1rem 20px 2rem;
    }
    .getting-started-section{
        .getting-started-box{
            padding: 25px;
        }
        .getting-started-right-box{
            left: 0rem;
            min-height: auto;
            margin-bottom: 2rem;
            .info{
                left: 0rem;
                padding: 30px;
                h2{
                    font-size: 30px;
                    line-height: 45px;
                    margin-bottom: 25px;
                }
                a{
                    display: block;
                    font-size: 16px;
                    padding: 10px 20px;
                }
            }
        }
    }
    .sales-info-section .info-box{
        padding: 25px;
        .div-row{
            display: block;
            padding-top: 25px;
            ul{
                li{
                    h3{
                        font-size: 20px;
                    }
                }
            }
            .right{
                text-align: left;
                margin-top: 15px;
            }
        }
        .info_p{
            margin: 25px 0px 0px;
        }
    }
    .steps-section{
        padding: 25px 20px 10px;
        .relative{
            .stepsnumber{
                top: 45px;
            }
            .steps-box{
                height: 350px;
                h3{
                    font-size: 17px;
                    line-height: 28px;
                    margin-top: 6rem;
                }
            }
        }
    }
}
@media (min-width:769px) and (max-width:960px){
    .affiliate-header-section{
        .header-text{
            .go-to-home{
                padding: 10px 40px;
                font-size: 16px;
            }
        }
    }
    .affiliate-options .option-box{
        margin: 15px 10px 20px 10px;
        padding: 50px 10px;
    }
    .affiliate-program-section, .sales-info-section {
        padding: 2rem 20px 2rem;
    }
    .getting-started-section{
        margin-bottom: 2rem;
        .getting-started-right-box{
            left: -1rem;
            .info{
                left: 0rem;
                h2{
                    line-height: 42px;
                    font-size: 30px;
                    margin-bottom: 25px;
                }
                a{
                    display: block;
                    padding: 10px 20px;
                }
            }
        }
    }
    .steps-section .relative{
        .stepsnumber{
            height: 195px;
            right: 55px;
            top: 102px;
            &::after{
                right: -60px;
                border-left: 60px solid $primary;
                border-top: 98px solid transparent;
                border-bottom: 98px solid transparent;
            }
            &.secondary-color{
                &::after{
                    border-left: 60px solid $secondary;
                }
            }
        }
        .steps-box{ 
            h3{
                font-size: 15px;
                line-height: 20px;
                margin-top: 6rem;
            p{
                font-size: 45px;
                margin: 2rem 0rem 1rem;
            }
            }
            .steps-icon{
                left: 2px;
                top: 102px;
                width: 70px;
                height: 70px;
            }
        }
    }
    .sales-info-section .info-box .div-row ul li h3{
        font-size: 24px;
    }
}
@media (min-width:961px) and (max-width:1199px){
    .affiliate-program-section, .sales-info-section{
        padding: 2rem 20px 2rem;
    }
    .getting-started-section{
        padding-bottom: 1.5rem;
        .getting-started-right-box{
            left: -2rem;
            .info{
                padding: 30px;
                left: 2.5rem;
                h2{
                    font-size: 32px;
                    line-height: 48px;
                }
                a{
                    display: block;
                }
            }
        }
    }
    .steps-section .relative .steps-box h3{
        font-size: 18px;
        line-height: 26px;
        p{
            font-size: 56px;
        }
    }
}
@media (min-width:1200px) and (max-width:1460px){
    .steps-section .relative .steps-box h3{
        font-size: 20px;
        line-height: 28px;
    }
}
@media (max-width:768px){
    .affiliate-header-section .header-text .header-text-box{
        margin-top: 50px;
        margin-bottom: 3rem;
    }
}