@import '../../assets/scss/variables';

.compatible-devices-section{
    p{
        span{
            color: $secondary;
        }
    }
    .p-tabview{
        .p-tabview-nav-container{
            .p-tabview-nav-content{
                .p-tabview-nav{
                    justify-content: space-between;
                    .p-unselectable-text{
                        .p-tabview-nav-link{
                            padding: 1.7rem 1.8rem;
                            &:after{
                                content: "";
                                width: 2px;
                                height: 20px;
                                background: #D7E2EC;
                                right: 0px;
                                position: absolute;
                            }
                            .p-tabview-title{
                                font-size: 17px;
                            }
                        }
                        &.p-highlight{
                            .p-tabview-nav-link{
                                &:after{
                                    display: none;
                                }
                            }  
                        }
                        &.last-tab{
                            .p-tabview-nav-link{
                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .p-tabview-panels{
            .device-panel{
                border-radius: 10px;
                border: 1px solid #EAF5FA;
                background: #FCFEFF;
                padding: 2rem;
                h2{
                    margin: 0px;
                    padding: 0px 20px;
                    font-size: 18px;
                    color: $primary;
                }
                ul{
                    li{
                        list-style: none;
                        margin: 25px 0px;
                        position: relative;
                        &::before{
                            position: absolute;
                            content: "";
                            height: 8px;
                            width: 8px;
                            left: -22px;
                            top: 7px;
                            border-radius: 10px;
                            background:$secondary;
                        }
                    }
                }
                .line{
                    width: 100%;
                    max-width: 350px;
                    margin: 2.5rem auto;
                    height: 1px;
                    background: $secondary;
                    position: relative;
                    &::before{
                        width: 6px;
                        height: 6px;
                        background: $secondary;
                        position: absolute;
                        content: '';
                        left: 0px;
                        transform: rotate(45deg);
                        top: -3px;
                    }
                    &::after{
                        width: 6px;
                        height: 6px;
                        background: $secondary;
                        position: absolute;
                        content: '';
                        right: 0px;
                        transform: rotate(45deg);
                        top: -3px;
                    }
                }
                .devices-img{
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
    &::after{
        bottom: 10%;
        z-index: -1;
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1460px){
    .compatible-devices-section{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 1.7rem 1.5rem;
                                .p-tabview-title{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}