@import "../../assets/scss/variables";

// **** Home Page CSS **** //
@mixin button {
  padding: 24px 40px;
  box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.3);
  background: $btnprimaryover;
  border: none;
  margin-top: 1rem;
  border-radius: 10px;
  width: 100%;
  max-width: 265px;
  .p-button-label {
    font-size: 18px;
    font-family: $font-base;
    font-weight: 600;
  }
  &:hover {
    background: $btnprimary;
  }
}

.header-section {
  padding: 15px 165px;
  background: url(../../assets/images/header-banner.jpg) no-repeat;
  min-height: 950px;
  background-size: cover !important;
  background-position: right !important;
  .header-text {
    text-align: center;
    .header-text-box {
      border-radius: 10px;
      background: rgba(10, 9, 23, 0.75);
      padding: 60px 45px;
      color: $white;
      width: 90%;
      max-width: 975px;
      margin: 170px auto 0px;
      h1 {
        font-size: 48px;
        font-weight: 700;
        margin: 0px;
        line-height: 65px;
      }
      p {
        font-size: 24px;
        font-family: $font-secondary;
        font-weight: 400;
        margin: 20px 0px 0px;
        line-height: 36px;
      }
      .goto-plans-button {
        @include button;
      }
      .country-dropdown {
        margin-top: 2rem;
        .p-dropdown {
          padding: 6px 25px;
          border-radius: 10px;
          background: $btnprimary;
          margin: 0px 0px;
          border: none;
          width: 100%;
          max-width: 365px;
          .p-dropdown-label {
            font-family: $font-base;
            font-size: 18px;
            font-weight: 500;
            color: $white;
            text-align: left;
          }
          .p-dropdown-trigger {
            padding: 0px;
            width: 1rem;
            .p-dropdown-trigger-icon {
              color: $white;
            }
          }
        }
      }
    }
  }
}
.options-section {
  position: relative;
  top: -5rem;
  padding: 0px 165px;
  .option-box {
    background: $white;
    border-radius: 10px;
    padding: 60px 30px;
    text-align: center;
    box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.05);
    margin: 0px 40px 0px 0px;
    height: 92%;
    h2 {
      margin: 35px 0px 15px;
      font-size: 28px;
      font-weight: 600;
    }
    p {
      font-size: 18px;
      margin: 0px 0px 15px;
      font-family: $font-secondary;
      line-height: 32px;
      opacity: 0.6;
    }
  }
}
.offer-section {
  padding: 5rem 165px 0px;
  background: $secondary;
  color: $white;
  h2 {
    font-size: 48px;
    line-height: 75px;
  }
  p {
    font-size: 18px;
    font-family: $font-secondary;
    line-height: 32px;
  }
  .choose-plans-button {
    @include button;
    &:hover {
      background: $white;
      color: $primary;
    }
  }
  .hand-iphone-img {
    position: relative;
    top: 15px;
  }
}
.plan-section {
  padding: 0rem 165px;
  h2 {
    font-weight: 700;
    font-size: 48px;
    margin: 0px 0px 2rem;
  }
  .esim-icon {
    line-height: 0px;
    img {
      width: 100%;
      max-width: 185px;
    }
  }
  &.heading {
    position: relative;
    padding-top: 18rem;
  }
  .bundles-section {
    position: relative;
    margin-top: 0rem;
    .country-dropdown {
      position: relative;
      top: -15rem;
      .choose-region-button {
        border-radius: 10px;
        border: 1px solid $secondary;
        padding: 20px 65px;
        background: $white;
        margin-left: 12px;
        .p-button-label {
          font-family: $font-base;
          font-size: 18px;
          font-weight: 500;
          color: $secondary;
        }
        &:hover {
          background: $secondary;
          .p-button-label {
            color: $white;
          }
        }
        &.p-dropdown-empty {
          background: $white;
          border: 1px solid $secondary;
          .p-button-label {
            color: $secondary;
          }
          &:hover {
            background: $secondary;
            .p-button-label {
              color: $white;
            }
          }
        }
        &.active{
          background: $primary;
          border: 1px solid $primary;
          .p-button-label {
            color: $white;
          }
        }
      }
    }
    .plan-box {
      border-radius: 10px;
      border: 2px solid $input-border !important;
      background: $white;
      box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.04);
      font-family: $font-base;
      margin: 1rem;
      padding: 1rem 1.5rem 1.5rem;
      // height: 95%;
      h3 {
        margin: 0px;
        font-size: 24px;
        font-weight: 500;
        color: $primary;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .icons{
        line-height: 0px;
        img{
          width: 24px;
        }
      }
      .info-icon{
        cursor: pointer;
        &:hover{
          opacity: 0.75;
        }
      }
      h2 {
        font-size: 36px;
        font-weight: 600;
        color: $primary;
        margin: 0px 0px;
      }
      p {
        font-size: 16px;
        color: $primary;
        display: block;
        margin-bottom: 20px;
        font-weight: 700;
        span {
          font-size: 20px;
          opacity: 0.6;
          color: $primary;
          font-family: $font-secondary;
          margin-left: 15px;
        }
      }
      .goto-plans-button {
        @include button;
        padding: 20px 20px;
        border: 0px solid $btnprimaryover;
        background: $btnprimaryover;
        box-shadow: none;
        max-width: 100%;
        margin-top: 2rem;
        .p-button-label {
          color: $white;
        }
      }
      &:hover {
        border: 2px solid $btnprimary !important;
        .goto-plans-button {
          background: $btnprimary;
          .p-button-label {
            color: $white;
          }
        }
      }
      ul {
        margin: 0px;
        padding: 0px;
      }
    }
    .p-carousel-container {
      .p-link {
        width: 50px;
        height: 50px;
        border: 2px solid $white;
        background: $primary;
        margin: 0 auto;
        border-radius: 10px;
        .p-carousel-next-icon {
          width: 18px;
          height: 18px;
          color: $white;
        }
        .p-carousel-prev-icon {
          color: $white;
        }
        &:hover {
          background: $primary;
          border: 2px solid $white;
          .p-carousel-next-icon {
            color: $white;
          }
        }
        &.p-carousel-prev {
          z-index: 1;
        }
        &.p-carousel-next {
          z-index: 1;
        }
        &.p-disabled {
          background: $white;
          opacity: 0.75;
          .p-carousel-prev-icon {
            color: $primary;
          }
          .p-carousel-next-icon {
            color: $primary;
          }
        }
      }
      .p-carousel-items-content {
        margin-top: 0rem;
      }
    }
    .p-carousel-indicators {
      display: none;
      .p-carousel-indicator {
        .p-link {
          border-radius: 5px;
        }
        &.p-highlight {
          .p-link {
            background: $secondary;
          }
        }
      }
    }
    .country-dropdown {
      .label-span {
        font-weight: 600;
        color: $primary;
        font-size: 18px;
        margin-bottom: 10px;
        display: block;
        text-align: left;
      }
      .p-dropdown {
        padding: 8px 20px 8px 10px;
        border-radius: 10px;
        border: 1px solid $primary;
        background: $white;
        margin: 0px 0px;
        width: 100%;
        max-width: 410px;
        min-width: 410px;
        .p-dropdown-label {
          font-family: $font-base;
          font-size: 18px;
          font-weight: 600;
          color: $primary;
        }
        .p-dropdown-trigger {
          padding: 0px;
          width: 1rem;
          .p-dropdown-trigger-icon {
            color: $primary;
          }
        }
        &:hover {
          background: $primary;
          
          .p-dropdown-label {
            color: $white;
          }
          
          .p-dropdown-trigger-icon {
            color: $white;
          }
        }
        .p-dropdown-item.p-highlight {
          background-color: $primary;
          color: white; 
        }
        &.active{
          background: $btnprimary;
          border-color: $btnprimary;
          .p-dropdown-label {
            color: $white;
          }
          .p-dropdown-trigger {
            .p-dropdown-trigger-icon {
              color: $white;
            }
          }
        }
      }
      .buttons-section {
        display: inline;
        margin-left: 10px;
        .p-dropdown {
          background: $white;
          border: 1px solid $secondary;
          .p-dropdown-label {
            color: $secondary;
          }
          .p-dropdown-trigger {
            .p-dropdown-trigger-icon {
              color: $secondary;
            }
          }
          &:hover {
            background: $secondary;
            .p-dropdown-label {
              color: $white;
            }
            .p-dropdown-trigger {
              .p-dropdown-trigger-icon {
                color: $white;
              }
            }
          }
          &.active{
            background: $primary;
            border-color: $primary;
            .p-dropdown-label {
              color: $white;
            }
            .p-dropdown-trigger {
              .p-dropdown-trigger-icon {
                color: $white;
              }
            }
          }
        }
      }
    }
    .skeleton-loading-section {
      display: flex;
    }
    .region-select-section{
      border-radius: 10px;
      border: 1px solid $btnprimaryover;
      background: $white;
      display: inline-block;
      .select-buttons{
        border: none;
        border-radius: 10px;
        padding: 20px 65px;
        background: $white;
        .p-button-label {
          font-family: $font-base;
          font-size: 18px;
          font-weight: 500;
          color: $teritary;
        }
        &:hover {
          background: $btnprimary;
          .p-button-label {
            color: $white;
          }
        }
        &.active{
          background: $btnprimaryover;
          .p-button-label {
            color: $white;
          }
        }
        &:focus{
          box-shadow: none;
        }
      }
    }
  }
  &.data-plans-section {
    padding: 3rem 165px;
    margin-bottom: 10rem;
    min-height: 588px;
    .p-carousel {
      position: relative;
      top: 9.3rem;
    }
  }
  .no-catalog-data{
      border-radius: 10px;
      border: 2px solid $input-border;
      background: $white;
      box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 235px;
      width: 100%;
      justify-content: center;
      margin: 0 auto;
      position: absolute;
      bottom: -32rem;
      p{
        font-size: 22px;
        margin: 15px 0px 0px;
        color: $primary;
      }
  }
}
.benefits-section {
  padding: 0rem 165px 2rem;
  background: $quaternary;
  h2 {
    font-size: 48px;
    font-weight: 700;
  }
  .benefits-img {
    margin-top: 2rem;
    display: block;
  }
  .benefits-icon {
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $teritary;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .benefits-text {
    color: $primary;
    h3 {
      font-size: 20px;
      font-weight: 600;
      margin: 0px 0px 12px;
    }
    p {
      font-size: 18px;
      font-family: $font-secondary;
      opacity: 0.6;
      line-height: 30px;
      margin: 0px;
    }
  }
}
.partners-section {
  padding: 2rem 165px;
  background: $quaternary;
  .partners-text-box {
    text-align: center;
    background: $white;
    border-radius: 40px;
    color: $primary;
    min-height: 645px;
    position: relative;
    z-index: 1;
    border: 1px solid $input-border;
    h2 {
      margin: 0px 0px 15px;
      font-size: 48px;
      font-weight: 700;
    }
    p {
      font-size: 24px;
      margin: 0px 0px 60px;
      font-weight: 500;
    }
    .goto-plans-button {
      @include button;
    }
  }
  .benefits-img {
    position: relative;
    left: -8rem;
  }
}
.review-section {
  padding: 3rem 165px;
  background: $quaternary;
  h2 {
    font-weight: 700;
    font-size: 48px;
    text-align: center;
  }
  .reviews-box {
    .reviews-list {
      border-radius: 8px;
      border: 2px solid $input-border !important;
      background: $white;
      box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
      font-family: $font-base;
      min-height: 406px;
      padding: 2rem 3rem;
      margin: 1rem;
      ul {
        margin: 0px 0px 15px;
        padding: 0px;
        li {
          list-style: none;
          display: inline-block;
          margin-right: 5px;
          .p-image {
            img {
              width: 20px;
            }
          }
        }
      }
      p {
        font-size: 22px;
        color: $primary;
        font-family: $font-secondary;
        opacity: 0.8;
        line-height: 40px;
        margin: 0px 0px 15px;
      }
      .avatar-text {
        h4 {
          font-size: 17px;
          color: $primary;
          margin: 0px 0px 6px;
          font-weight: 600;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          opacity: 0.4;
          color: $primary;
        }
      }
    }
  }
  .p-carousel-container {
    position: relative;
    .p-link {
      position: absolute;
      margin: 0 auto;
      left: 0px;
      right: 0px;
      border: 1px solid $secondary;
      background: $secondary;
      width: 50px;
      height: 50px;
      top: 0px;
      .p-icon {
        width: 20px;
        height: 20px;
        color: $white;
      }
      &:hover {
        background: $primary;
        border: 1px solid $primary;
        .p-icon {
          color: $white;
        }
      }
      &.p-carousel-prev {
        left: -65px;
        z-index: 1;
      }
      &.p-carousel-next {
        right: -65px;
        z-index: 1;
      }
      &.p-disabled {
        background: $quaternary;
        opacity: 0.9;
        .p-icon {
          color: $secondary;
        }
      }
    }
    .p-carousel-items-content {
      margin-top: 5rem;
    }
  }
  .p-carousel-indicators {
    .p-carousel-indicator {
      .p-link {
        border-radius: 25px;
        background-color: #dcdeff;
        width: 12px;
        height: 12px;
      }
      &.p-highlight {
        .p-link {
          background: $secondary;
        }
      }
    }
  }
}
.faq-section,
.partners-section {
  padding: 3rem 165px 4rem;
  background: $quaternary;
  h2 {
    margin: 0px 0px 40px;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
  }
  .p-accordion {
    margin: 0px 75px;
    .p-accordion-tab {
      margin-bottom: 20px;
      .p-accordion-header {
        .p-accordion-header-link {
          border-radius: 10px;
          background: $white;
          border: 1px solid $input-border;
          font-size: 22px;
          font-weight: 500;
          padding: 28px 90px 28px 40px;
          .p-accordion-toggle-icon {
            position: absolute;
            right: 4px;
            width: 56px;
            height: 56px;
            background: #f7f8fa;
            border-radius: 50px;
            color: $teritary;
            border: 1px solid $input-border;
            padding: 18px;
          }
          .p-accordion-header-text {
            line-height: 30px;
          }
        }
      }
      .p-toggleable-content {
        margin-top: 0rem;
        .p-accordion-content {
          border-radius: 10px;
          background: transparent;
          margin-bottom: 1rem;
          padding: 35px 40px;
          border: 1px solid #ededed;
          p {
            font-size: 18px;
            font-family: $font-secondary;
            line-height: 36px;
            opacity: 0.8;
            text-align: left;
          }
          ol,
          ul {
            li {
              font-size: 17px;
              font-family: $font-secondary;
              line-height: 36px;
              opacity: 0.8;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .faq-custom-margin {
    margin: 2.5rem 0px;
  }
  .view-all-button {
    @include button;
    margin: 2rem auto 1rem;
    text-align: center;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    padding: 24px 60px;
  }
}

// Responsive CSS

@media (min-width: 1200px) and (max-width: 1460px) {
  .header-section,
  .options-section,
  .offer-section,
  .plan-section,
  .benefits-section,
  .partners-section,
  .faq-section,
  .review-section {
    padding: 10px 40px;
  }
  .data-plans-section {
    padding: 3rem 40px !important;
  }
  .is-sticky {
    header {
      padding: 10px 40px;
    }
  }
  .header-section {
    min-height: 850px;
    .header-text {
      .header-text-box {
        padding: 50px 40px;
        h1 {
          font-size: 40px;
          line-height: 60px;
        }
      }
    }
  }
  .options-section {
    .option-box {
      padding: 50px 20px;
      margin: 0px 20px 0px 0px;
      height: 100%;
      p {
        font-size: 16px;
      }
    }
  }
  .offer-section {
    background-size: 185px;
    padding-bottom: 0px;
    h2 {
      font-size: 40px;
      line-height: 60px;
    }
  }
  .plan-section,
  .review-section,
  .faq-section {
    h2 {
      font-size: 40px;
      margin: 0px;
    }
  }
  .faq-section h2 {
    margin: 0px 0px 1rem;
  }
  .plan-section {
    .bundles-section {
      .plan-box {
        margin: 0.4rem;
        padding: 2rem 1rem;
      }
    }
  }
  .review-section {
    .reviews-box {
      .reviews-list {
        padding: 2rem 2rem;
        margin: 0.7rem;
        p {
          line-height: 32px;
          font-size: 16px;
        }
      }
    }
  }
  .benefits-section {
    h2 {
      font-size: 40px;
    }
    .option-box {
      .p-image {
        img {
          width: 100%;
        }
      }
    }
  }
  .partners-section {
    .partners-text-box {
      min-height: 490px;
      padding: 0px 1rem;
      h2 {
        font-size: 40px;
      }
    }
    .option-box {
      .benefits-img {
        left: -3rem;
        img {
          width: 100%;
        }
      }
    }
  }
}
.bundle-dialog {
  .p-dialog-title {
    color: $teritary;
    font-size: 24px;
  }
  .p-dialog-content {
    scrollbar-color: $secondary;
    scrollbar-width: thin;
    .data-item-header {
      h3 {
        margin: 5px 0px;
        &.color-h3 {
          color: $teritary;
        }
      }
    }
    .data-item-content {
      table {
        tbody {
          tr {
            display: flex;
            justify-content: space-between;
            margin: 5px 0px;
            padding: 12px 15px;
            border-radius: 5px;
            background: #fafdff;
            font-family: $font-secondary;
            td {
              .networkData {
                text-align: right;
                .catalogNetworks {
                  display: block;
                  margin-bottom: 10px;
                  font-size: 14px;
                }
              }
              .roamingData {
                .roaming-tooltip {
                  position: relative;
                  top: 2px;
                  left: 5px;
                  margin-right: 5px;
                  cursor: pointer;
                  &:hover {
                    background: $secondary;
                    color: $white;
                    border-radius: 10px;
                  }
                }
              }
              &:first-child {
                font-weight: 600;
                color: $primary;
                position: relative;
                padding-left: 1rem;
                &::before {
                  content: "";
                  width: 6px;
                  height: 6px;
                  background: $secondary;
                  border-radius: 10px;
                  position: absolute;
                  left: 0px;
                  top: 8px;
                }
              }
            }
            &.last-row {
              background: transparent;
              padding: 10px 0px;
              margin: 0px;
              td {
                padding-left: 0px;
                &::before {
                  display: none;
                }
                .buynow-btn {
                  @include button;
                  padding: 15px 40px;
                  margin-top: 0rem;
                  min-width: 220px;
                }
              }
            }
          }
        }
      }
    }
    .roamingColumnsDialog {
      grid-template-columns: auto auto auto auto;
      display: grid;
      ul {
        li {
          margin: 15px 0px;
          font-size: 15px;
          font-family: $font-secondary;
          &::marker {
            color: $secondary;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $primary;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $secondary;
      border-radius: 10px;
    }
  }
}
@media (max-width: 960px) {
  @mixin button {
    padding: 12px 15px;
    max-width: 220px;
    .p-button-label {
      font-size: 16px;
    }
  }
  .header-section,
  .options-section,
  .offer-section,
  .plan-section,
  .benefits-section,
  .faq-section,
  .partners-section,
  .review-section {
    padding: 25px 20px;
  }
  .plan-section {
    &.heading {
      top: -1rem;
    }
  }
  .data-plans-section {
    padding: 25px 20px !important;
    .logo_icon {
      display: none;
    }
  }
  .is-sticky {
    header {
      padding: 5px 20px;
    }
  }
  .header-section {
    min-height: auto;
    padding: 5px 20px;
    background-position: center !important;
    .header-text {
      .header-text-box {
        padding: 20px;
        margin-bottom: 5rem;
        margin-top: 60px;
        h1 {
          padding-right: 0px;
          font-size: 26px;
          line-height: 40px;
        }
        p {
          padding-right: 0px;
          font-size: 18px;
          line-height: 30px;
        }
        .goto-plans-button {
          @include button;
        }
      }
    }
  }
  .options-section {
    top: 0rem;
    .option-box {
      padding: 20px;
      margin: 0px;
      h2 {
        font-size: 22px;
        margin: 20px 0px 15px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .offer-section {
    background-image: none;
    padding-bottom: 0px;
    .hand-iphone-img {
      img {
        width: 100%;
        max-width: 85%;
      }
    }
    h2 {
      font-size: 26px;
      line-height: 40px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
    .choose-plans-button {
      @include button;
    }
    .column-reverse {
      flex-direction: column-reverse;
    }
  }
  .plan-section {
    h2 {
      font-size: 26px;
      line-height: 40px;
    }
    .esim-icon {
      margin-left: 1.5rem;
      img {
        width: 75px;
      }
    }
    .bundles-section {
      margin-top: 1rem;
      .country-dropdown {
        position: inherit;
        right: auto;
        top: auto;
        top: -12rem;
        .label-span {
          font-size: 16px;
          margin-right: 0px;
          margin-bottom: 8px;
          display: block;
        }
        .p-dropdown {
          padding: 3px 15px;
          width: 100%;
          text-align: left;
          .p-dropdown-label {
            font-size: 16px;
          }
        }
        .choose-region-button {
          padding: 3px 15px 3px 5px;
        }
        .buttons-section{
          .p-dropdown{
            padding: 3px 15px 3px 5px;
            margin-left: 0px;
          }
        }
      }
      .plan-box {
        padding: 1.5rem;
        margin: 1rem 2rem;
        h3 {
          font-size: 18px;
        }
        h2 {
          font-size: 30px;
        }
        p {
          span {
            font-size: 16px;
          }
        }
        .goto-plans-button {
          @include button;
          max-width: 100%;
        }
      }
      .p-carousel-container {
        .p-link {
          top: auto;
          width: 40px;
          height: 40px;
        }
      }
    }
    &.data-plans-section {
      margin-top: 5rem;
      margin-bottom: 8rem;
      .p-carousel {
        top: 7.8rem;
      }
    }
  }
  .benefits-section {
    padding-top: 0rem;
    h2 {
      font-size: 26px;
      line-height: 40px;
    }
    .benefits-text {
      h3 {
        font-size: 16px;
        margin: 0px 0px 5px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
    .benefits-icon {
      min-width: 80px;
      height: 80px;
      .hand-iphone-img {
        img {
          width: 100%;
          max-width: 45px;
        }
      }
    }
    .benefits-img {
      img {
        width: 100%;
      }
    }
  }
  .partners-section {
    padding-bottom: 0px;
    .partners-text-box {
      min-height: auto;
      padding: 2rem 1rem;
      h2 {
        font-size: 26px;
        line-height: 40px;
        margin: 0px 0px 20px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
        margin: 0px 0px 25px;
      }
      .view-all-button {
        @include button;
        font-size: 16px;
      }
    }
    .benefits-img {
      left: auto;
      top: -5rem;
      img {
        width: 100%;
      }
    }
  }
  .review-section {
    h2 {
      font-size: 26px;
      line-height: 40px;
      margin: 0px 0px 0px;
    }
    .p-carousel-container {
      .p-link {
        width: 40px;
        height: 40px;
        top: 25px;
      }
    }
    .reviews-box {
      .reviews-list {
        min-height: auto;
        padding: 1rem 1rem;
        p {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    .p-carousel {
      .p-carousel-indicators {
        padding: 0px;
      }
    }
  }
  .faq-section {
    h2 {
      font-size: 26px;
      line-height: 40px;
      margin: 0px 0px 15px;
    }
    .view-all-button {
      @include button;
      font-size: 16px;
      margin: 0rem auto 1rem;
    }
    .p-accordion {
      margin: 0px 0px;
      .p-accordion-tab {
        .p-accordion-header {
          .p-accordion-header-link {
            padding: 15px 60px 15px 20px;
            font-size: 16px;
            border-radius: 5px;
            .p-accordion-toggle-icon {
              width: 45px;
              height: 45px;
              padding: 15px;
              right: -3px;
            }
            .p-accordion-header-text {
              line-height: 24px;
              font-size: 15px;
            }
          }
        }
        .p-toggleable-content {
          .p-accordion-content {
            padding: 40px 20px 30px;
            p {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 769px) and (max-width: 960px) {
  .header-navigation {
    .p-menubar {
      display: flex;
      .p-menubar-end {
        margin-top: 0px;
        margin-left: auto;
      }
    }
  }
  .options-section {
    .option-box {
      padding: 15px;
      min-height: 290px;
    }
  }
  .offer-section {
    .column-reverse {
      flex-direction: row;
    }
  }
  .plan-section {
    .bundles-section {
      .country-dropdown {
        text-align: left;
        .label-span {
          margin-right: 25px;
          display: block;
        }
        .p-dropdown {
          width: auto;
          max-width: 350px;
          min-width: 350px;
        }
        .buttons-section {
          .p-dropdown {
            padding: 3px 15px 3px 5px !important;
          }
          .p-button{
            margin-left: 12px;
            padding: 13px 60px;
          }
        }
        .region-select-section {
          .select-buttons{
            padding: 14px 50px;
          }
        }
      }
    }
    .bundles-section {
      .plan-box {
        margin: 1rem 1rem;
      }
    }
    &.data-plans-section{
      min-height: 535px;
    }
  }
  .partners-section {
    .benefits-img {
      top: 0rem;
      left: -1rem;
    }
    .partners-text-box {
      left: 1rem;
    }
  }
  .bundle-dialog {
    width: 90% !important;
  }
  .review-section {
    .reviews-box {
      .reviews-list {
        p {
          font-size: 16px;
        }
      }
    }
  }
}
@media (min-width: 961px) and (max-width: 1199px) {
  .header-section,
  .options-section,
  .offer-section,
  .plan-section,
  .benefits-section,
  .faq-section,
  .partners-section,
  .review-section {
    padding: 25px 20px;
  }
  .data-plans-section {
    padding: 25px 20px !important;
  }
  .is-sticky {
    header {
      padding: 10px 20px;
    }
  }
  .header-section {
    min-height: auto;
    padding: 10px 20px;
    .header-text {
      .header-text-box {
        padding: 20px;
        margin-bottom: 5rem;
        margin-top: 60px;
        h1 {
          padding-right: 0px;
          font-size: 36px;
          line-height: 45px;
        }
        p {
          padding-right: 0px;
          font-size: 18px;
          line-height: 30px;
        }
        .goto-plans-button {
          @include button;
        }
      }
    }
  }
  .options-section {
    top: -5rem;
    .option-box {
      padding: 25px 20px;
      margin: 0px;
      min-height: 325px;
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .offer-section {
    background-image: none;
    padding-bottom: 0px;
    .hand-iphone-img {
      img {
        width: 100%;
        max-width: 85%;
      }
    }
    h2 {
      font-size: 36px;
      line-height: 50px;
    }
  }
  .plan-section {
    h2 {
      font-size: 36px;
      line-height: 40px;
    }
    .esim-icon {
      margin-left: 1.5rem;
      img {
        width: 130px;
      }
    }
    &.data-plans-section {
      margin-top: 2rem;
      min-height: 520px;
      .bundles-section {
        margin-top: 0px;
        .country-dropdown {
          top: -10rem;
        }
      }
      .p-carousel {
        top: 7.8rem;
      }
    }
    &.heading {
      top: -9rem;
    }
    .bundles-section {
      margin-top: 4rem;
      .country-dropdown {
        .label-span {
          font-size: 16px;
          margin-bottom: 8px;
        }
        .p-dropdown {
          padding: 3px 15px;
          text-align: left;
          max-width: 350px;
          min-width: 350px;
          .p-dropdown-label {
            font-size: 16px;
          }
        }
        .choose-region-button {
          padding: 3px 20px 3px 5px;
          margin-left: 0px;
        }
        .buttons-section {
          .p-dropdown {
            padding: 3px 20px 3px 5px !important;
          }
          .p-button{
            margin-left: 12px;
            padding: 13px 60px;
          }
        }
        .region-select-section {
          .select-buttons{
            padding: 14px 50px;
          }
        }
      }
      .plan-box {
        padding: 1.5rem;
        margin: 1rem 0.8rem;
        h3 {
          font-size: 18px;
        }
        h2 {
          font-size: 36px;
        }
        p {
          span {
            font-size: 16px;
          }
        }
      }
      .p-carousel-container {
        .p-link {
          top: auto;
          bottom: -50px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .benefits-section {
    padding-top: 3rem;
    h2 {
      font-size: 36px;
      line-height: 40px;
    }
    .benefits-text {
      h3 {
        font-size: 16px;
        margin: 0px 0px 5px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
    .benefits-icon {
      min-width: 80px;
      height: 80px;
      .hand-iphone-img {
        img {
          width: 100%;
          max-width: 45px;
        }
      }
    }
    .benefits-img {
      img {
        width: 100%;
      }
    }
  }
  .partners-section {
    padding-bottom: 0px;
    .partners-text-box {
      min-height: auto;
      padding: 3rem 1rem;
      left: 2rem;
      h2 {
        font-size: 28px;
        line-height: 40px;
        margin: 0px 0px 20px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
        margin: 0px 0px 45px;
      }
      .view-all-button {
        @include button;
        font-size: 16px;
      }
    }
    .benefits-img {
      left: -2rem;
      top: 0rem;
      img {
        width: 100%;
      }
    }
  }
  .review-section {
    h2 {
      font-size: 36px;
      line-height: 40px;
      margin: 0px 0px 0px;
    }
    .p-carousel-container {
      .p-link {
        width: 40px;
        height: 40px;
        top: 25px;
      }
    }
    .reviews-box {
      .reviews-list {
        min-height: 295px;
        padding: 1rem 1rem;
        p {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    .p-carousel {
      .p-carousel-indicators {
        padding: 0px;
      }
    }
  }
  .faq-section {
    h2 {
      font-size: 36px;
      line-height: 40px;
      margin: 0px 0px 15px;
    }
    .view-all-button {
      @include button;
      font-size: 16px;
      margin: 0rem auto 1rem;
    }
    .p-accordion {
      margin: 0px 0px;
      .p-accordion-tab {
        .p-accordion-header {
          .p-accordion-header-link {
            padding: 20px 50px 20px 20px;
            font-size: 16px;
            .p-accordion-toggle-icon {
              width: 45px;
              height: 45px;
              padding: 15px;
            }
            .p-accordion-header-text {
              line-height: 24px;
            }
          }
        }
        .p-toggleable-content {
          .p-accordion-content {
            padding: 40px 20px 30px;
            p {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  .bundle-dialog {
    width: 85% !important;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .skeleton-loading-section {
    .medium-devices {
      width: 33.333%;
    }
  }
}
@media (max-width: 768px) {
  .bundle-dialog {
    width: 90% !important;
    .p-dialog-content {
      .data-item-header {
        h3 {
          &.color-h3 {
            text-align: left;
          }
        }
      }
      .data-item-content {
        table {
          tbody {
            tr {
              display: grid;
              td {
                &.text-right {
                  text-align: left !important;
                  margin-top: 5px;
                }
                .networkData {
                  .catalogNetworks {
                    text-align: left;
                    margin-top: 5px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
      .roamingColumnsDialog {
        display: block !important;
      }
    }
  }
  .faq-section {
    .faq-custom-margin {
      margin: 1.5rem 0px;
    }
  }
  .plan-section {
    &.data-plans-section {
      height: 300px;
      min-height: auto;
      margin-bottom: 14rem;
      .bundles-section {
        margin-top: 0rem;
      }
      .country-dropdown {
        top: -12rem !important;
        display: block !important;
        .p-dropdown {
          min-width: 75% !important;
          margin-bottom: 15px !important;
          max-width: 255px !important;
        }
        .choose-region-button {
          padding: 13px 35px !important;
          width: 97%;
          margin-left: 0px !important;
          margin-right: 0px !important;
          margin-bottom: 10px;
        }
        .p-dropdown {
          padding: 3px 15px !important;
        }
        .buttons-section {
          display: inline;
        }
        .label-span {
          margin-left: 15px;
        }
        .region-select-section{
          .select-buttons{
            padding: 14px 45px;
          }
        }
      }
      .p-carousel {
        top: 0rem;
      }
    }
    &.heading {
      top: -3rem;
    }
    .no-catalog-data{
      position: inherit;
      bottom: 0rem;
    }
  }
}

@media (min-width: 1461px) and (max-width: 1750px) {
  .header-section,
  .options-section,
  .offer-section,
  .plan-section,
  .benefits-section,
  .partners-section,
  .faq-section,
  .review-section {
    padding: 15px 40px 0px;
  }
  .is-sticky {
    header {
      padding: 15px 40px;
    }
  }
  .plan-section.data-plans-section {
    padding: 15px 40px;
  }
  .faq-section {
    padding-bottom: 40px;
  }
  .partners-section .option-box .benefits-img {
    left: -3rem;
    img {
      width: 100%;
    }
  }
}
@media (max-width: 560px) {
  .faq-section .view-all-button {
    display: block;
    font-size: 15px;
  }
  .plan-section {
    .bundles-section {
      .plan-box {
        padding: 1.5rem;
        margin: 1rem 0.5rem;
      }
      .p-carousel-container {
        .p-link {
          position: absolute;
          left: 0px;
          right: 0px;
          top: -40px;
          &.p-carousel-prev {
            left: -55px;
          }
          &.p-carousel-next {
            right: -55px;
          }
        }
      }
      .region-select-section .select-buttons{
        padding: 14px 25px !important;
        .p-button-label{
          font-size: 16px;
        }
      }
    }
    &.data-plans-section {
      margin-bottom: 18rem;
      .country-dropdown {
        top: -12rem !important;
        .p-dropdown {
          min-width: 100% !important;
        }
        .buttons-section {
          margin-left: 0px;
        }
        .choose-region-button {
          width: 100%;
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }
    &.heading {
      top: -7rem;
      padding-top: 6rem;
    }
  }
  .header-section .header-text .header-text-box {
    h1 {
      font-size: 18px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      line-height: 25px;
    }
  }
}
