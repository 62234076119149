@import '../../assets/scss/variables';

.maintenance-section{
    min-height: 945px !important;
    background: url(../../assets/images/maintenance-bg.jpg) no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
    .esim-icon{
        img{
            width: 100%;
            max-width: 179px;
        }
    }
    .header-text{
        .header-text-box{
            background: rgba(10, 9, 23, 0.75);
            border: 5px solid rgba(255, 255, 255, 0.10);
            margin-top: 10rem;
            h1{
                padding-right: 0rem;
                text-transform: uppercase;
                font-size: 60px;
                line-height: 65px;
                span{
                    display: block;
                    font-size: 50px;
                    font-weight: 400;
                    letter-spacing: 1px;
                }
            }
            p{
                padding-right: 0rem;
                font-weight: 500;
                font-size: 23px;
                line-height: 30px;
                margin-top: 1rem;
            }
            .email-block{
                max-width: 400px;
                margin-top: 2rem;
                position: relative;
                text-align: center;
                margin: 2rem auto 0px;
                .p-component{
                    height: 50px;
                    border-radius: 5px;
                    padding: 0px 1rem;
                }
                .send-button{
                    background: $secondary;
                    border: none;
                    position: absolute;
                    right: 4px;
                    padding: 0px;
                    height: 44px;
                    width: 44px;
                    top: 3px;
                    &:hover{
                        background: $primary;
                    }
                }
            }
        }
    }
    header{
        padding: 0.5rem 0;
    }
}

@media (max-width:768px){
    .maintenance-section{
        min-height: auto !important;
        .header-text{
            .header-text-box{
                margin-top: 4rem;
                h1{
                    font-size: 40px !important;
                    line-height: 45px;
                    span{
                        font-size: 25px !important;
                    }
                }
                p{
                    font-size: 18px;
                }
            }
        }
    }
}
@media (min-width:1200px) and (max-width:1460px){
    .page-not-found{
        background-position: 80% 0% !important;
    }
}