/* fonts */
:root {
  --font-base: 'Lato', sans-serif;
  --font-secondary: 'Lato', sans-serif;
  --general_font_size: "20px";
}

/* fonts */
$font-base: var(--font-base);
$font-secondary: var(--font-secondary);
$general_font_size: var(--general_font_size);


$primary: var(--primary);
$secondary: var(--secondary);
$teritary: var(--teritary);
$quaternary: var(--quaternary);
$quinary: var(--quinary);
$mainmenu: var(--mainmenu);
$mainmenuover: var(--mainmenuover);
$btnprimary: var(--btnprimary);
$btnprimaryover: var(--btnprimaryover);
$btnsecondary: var(--btnsecondary);
$btnsecondaryover: var(--btnsecondaryover);
$input-field: var(--input-field);
$input-border: var(--input-border);
$dropdown-bg: var(--dropdown-bg);
$dropdown-selected: var(--dropdown-selected);
$white: var(--white);
$black: var(--black);
$error-msg: var(--error-msg);
