@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 16px 32px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $secondary;
    border: none;
    margin-top: 1rem;
    border-radius: 50px;
    width: 100%;
    max-width: 200px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $primary;
    }
  } 

.country-header-section{
    background: $white;
    .header-navigation{
        background: $primary;
        padding: 15px 165px;
    }
}
.country-info-section{
    padding: 60px 165px 100px;
    h1{
        font-size: 55px;
        font-weight: 700;
        color: $primary;
        line-height: 66px;
        span{
            color: $teritary;
        }
    }
    p{
        font-size: 28px;
        font-weight: 400;
        line-height: 45px;
        margin-right: 6rem;
    }
    .country-image{
        img{
            width: 100%;
            max-width: 560px;
        }
    }
    ul{
        padding: 0px;
        display: flex;
        gap: 75px;
        li{
            list-style: none;
            a{
                font-size: 24px;
                font-weight: 700;
                color: $secondary;
                border-bottom: 1px solid;
                padding-bottom: 3px;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
}
.country-bundle-h2{
    font-size: 36px !important;
    font-weight: 600 !important;
    margin: 0px 8px !important;
    position: relative;
    top: -13rem;
}

// Responsive CSS

.is-sticky .header-navigation{
    padding: 0 !important;
}

@media (min-width: 961px) and (max-width: 1199px){
    .inner-header-section.aboutus-header-section .header-navigation{
        padding: 10px 20px;
    }
    .country-header-section{
        .header-navigation{
            padding: 10px 20px;
        }
    }
    .country-info-section{
        padding: 10px 20px;
    }
}
@media (min-width: 1200px) and (max-width: 1460px){
    .inner-header-section.aboutus-header-section .header-navigation{
        padding: 10px 40px;
    }
    .country-header-section{
        .header-navigation{
            padding: 10px 40px;
        }
    }
    .country-info-section{
        padding: 40px 40px;
        h1{
            font-size: 50px;
            line-height: 60px;
        }
        p{
            font-size: 24px;
            line-height: 40px;
            margin-right: 2rem;
        }
    }
}
@media (max-width: 960px){
    .inner-header-section.aboutus-header-section .header-navigation {
        padding: 5px 20px;
        min-height: auto;
    }
    .inner-header-section .header-text p{
        max-width: 100% !important;
    }
    .country-header-section{
        .header-navigation{
            padding: 10px 20px;
        }
    }
    .country-info-section{
        padding: 10px 20px;
    }
}

@media (min-width: 1461px) and (max-width: 1750px){
    .country-header-section{
        .header-navigation{
            padding: 10px 40px;
        }
    }
    .country-info-section{
        padding: 40px 40px;
    }
}
@media (max-width: 768px){
    .country-info-section{
        h1{
            margin: 0px;
            font-size: 28px;
            line-height: 38px;
        }
        p{
            font-size: 20px;
            line-height: 36px;
            margin-right: 0rem;
        }
        ul{
            display: block;
            li{
                display: inline-block;
                margin-bottom: 15px;
                a{
                    font-size: 18px;
                    margin-right: 25px;
                }
            }
        }
    }
    .plan-section.heading {
        top: 0rem;
        padding-top: 7rem;
        .country-bundle-h2{
            font-size: 20px !important;
        }
    }
    .plan-section.data-plans-section {
        margin-bottom: 22rem;
    }
    .country-bundle-h2 {
        font-size: 24px !important;
        top: -5rem;
        left: 10px;
    }
}
@media (min-width: 769px) and (max-width: 960px) {
    .country-info-section{
        padding: 40px 20px;
        h1{
            margin: 0px;
            font-size: 28px;
            line-height: 38px;
        }
        p{
            font-size: 20px;
            line-height: 36px;
            margin-right: 2rem;
        }
        ul{
            display: block;
            li{
                display: inline-block;
                margin-bottom: 15px;
                a{
                    font-size: 18px;
                    margin-right: 25px;
                }
            }
        }
    }
    .country-bundle-h2{
        top: -3rem;
        left: 10px;
        font-size: 24px !important;
    }
    .plan-section.heading {
        padding-top: 7rem;
    }
}
@media (max-width: 560px){
    .country-bundle-h2 {
        font-size: 24px !important;
        top: -5rem;
    }
}

@media (min-width:1460px) and (max-width:1760px){
    .plan-section .bundles-section .country-dropdown {
        top: -12.5rem;
    }
}
@media (min-width: 961px) and (max-width: 1199px) {
    .country-info-section{
        padding: 40px 20px;
        h1{
            margin: 0px;
            font-size: 28px;
            line-height: 38px;
        }
        p{
            font-size: 20px;
            line-height: 36px;
            margin-right: 2rem;
        }
        ul{
            display: block;
            li{
                display: inline-block;
                margin-bottom: 15px;
                a{
                    font-size: 18px;
                    margin-right: 25px;
                }
            }
        }
    }
    .country-bundle-h2{
        top: 2rem;
        left: 10px;
        font-size: 24px !important;
    }
    .plan-section.heading {
        padding-top:10rem;
    }
    .plan-section.data-plans-section .bundles-section .country-dropdown {
        top: -11rem;
    }
}