@import '../../assets/scss/variables';

// **** Privacy Policy Page CSS **** //
.privacy-policy-section{
    .content-table{
        width: 100%;
        border: 1px solid $primary;
        border-collapse: collapse;
        margin: 1.5rem 0px;
        thead{
            tr{
                th{
                    background: $primary;
                    border: 1px solid $primary;
                    padding: 20px 15px;
                    font-size: 16px;
                    font-weight: 500;
                    color: $white;
                    &.min-width{
                        width: 285px;
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    border: 1px solid #E7EBFF;
                    padding: 10px 15px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    &::before{
                        content: attr(data-label);
                        display: inline-block;
                        line-height: 1.5;
                        margin-left: -100%;
                        width: 100%;
                        white-space: nowrap;
                    }
                    ol{
                        padding: 0px 15px;
                        margin-top: 0px !important;
                        li{
                            margin-bottom: 5px;
                            font-weight: 400;
                            &::before{
                                content: counter(item) !important;
                                left: 2px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-p{
        span{
            display: block;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 10px;
            position: relative;
            padding-left: 2rem;
            &::before{
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 10px;
                background: $white;
                border: 1px solid $secondary;
                left: 15px;
                top: 8px;
            }
        }
    }
}
@import '../../assets/scss/variables';

// **** Terms and Conditions Page CSS **** //
.tc-block{
    .terms-and-conditions-section{
        padding: 2rem 2rem;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        p{
            font-size: 16px;
            line-height: 30px;
            margin: 10px 0px 10px;
            font-family: $font-secondary;
            a{
                color: $primary;
                text-decoration: underline;
            }
        }
        ol{
            margin: 0px;
            padding: 5px 0px;
            counter-reset: item;
            &.sub-number-ol{
                counter-reset: item;
                list-style-type: none;
                margin: 0px;
                padding: 5px 0px;
                li{
                    counter-increment: item;
                    margin-bottom: 25px;
                    font-family: $font-secondary;
                    .heading{
                        font-size: 18px;
                        font-weight: 600;
                        color: $primary;
                        font-family: $font-base;
                        margin-bottom: 1rem;
                    }
                    &::before{
                        content: counters(item,".") " ";
                        font-weight: 500;
                        color: $white;
                        font-size: 16px;
                        background: $teritary;
                        border-radius: 5px;
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        text-align: center;
                        line-height: 30px;
                        margin-right: 8px;
                    }
                    ol{
                        counter-reset: item;
                        padding: 5px 25px;
                        margin-top: 8px;
                        li{
                            counter-increment: item;
                            list-style-type: none;
                            line-height: 28px;
                            font-size: 15px;
                            font-weight: 400;
                            margin-bottom: 10px;
                            padding-left: 25px;
                            position: relative;
                            &::before{
                                content: counters(item,".") " ";
                                position: absolute;
                                left: -10px;
                                font-size: 15px;
                                background: transparent;
                                width: auto;
                                height: auto;
                                color: $teritary;
                                line-height: 28px;
                                margin-right: 0px;
                            }
                            ol{
                                li{
                                    padding-left: 30px;
                                    &::before{
                                        left: -20px;
                                    }
                                }
                            }
                        }
                    }
                    a{
                        color: $primary;
                        text-decoration: underline;
                    }
                }
            }
            ul{
                li{
                    &::before{
                        display: none !important;
                    }
                }
            }
        }
    }
    &::after{
        content: '';
        bottom: 10%;
        background-size: 65%;
    }
    &::before{
        background-size: 65%;
    }
}
@media (max-width:960px){
    .tc-block{
        .terms-and-conditions-section{
            padding: 1rem 20px;
            .table-responsive{
                width: 100%;
                overflow: scroll;
                .content-table{
                    width: 900px;
                }
            }
            ol.sub-number-ol li a{
                word-break: break-all;
            }
        }
    }
}